import {
  AuthRequest,
  FileUploader,
  createUniqueNameForFile,
  trimTrailingSlash,
} from "../../lib";

/** @enum {string} */
const ImageType = {
  profile: { path: "profile" },
};

export const SysActions = {
  GET_SYSTEM_DATA: "GET_SYSTEM_DATA",

  getSystemData(forceRefresh = false) {
    return async function gettingSystemData(dispatch, getState) {
      const sysCache = getState().sys;
      //do not re-request system settings if values are persisted and have been retrieved today (this contingency ensures that updates are retrieved once on the initial load each day)
      if (
        !forceRefresh &&
        sysCache &&
        Object.keys(sysCache).length > 1 &&
        sysCache.datestamp === new Date().toDateString()
      ) {
        return;
      }

      const [sysResponse, jewishUProgramResponse] = await Promise.all([
        AuthRequest.get(
          `System/Public?type=EduPortal&portalRedirectsDomain=${window.location.hostname}`,
        ),
        AuthRequest.get("EduPrograms/byType/JewishU?includeProgramCounts=true"),
      ]);

      //get jewishU program identifier
      const jewishUProgram = jewishUProgramResponse.data.payload;
      if (jewishUProgram) {
        //spreading JU program to allow easier access
        const { id, configs, ...program } = jewishUProgram;
        sysResponse.data.payload.jewishUProgramID = id;
        sysResponse.data.payload.jewishUProgramConfigs = configs;
        sysResponse.data.payload.jewishUProgram = program;
      }

      //setting retrieval datestamp for cache refresh purposes
      sysResponse.data.payload.datestamp = new Date().toDateString();

      dispatch({
        type: SysActions.GET_SYSTEM_DATA,
        payload: sysResponse.data.payload,
      });
    };
  },
  /** @param {File} file
   * @param {FileType} fileType
   */
  uploadFile(file, fileType) {
    fileType = ("" + fileType).trim();
    const fileTypeInfo = ImageType[fileType];
    if (!fileTypeInfo) {
      throw new Error(`FileType not found: ${fileTypeInfo}`);
    }
    return async function uploadingFile(/* dispatch, getState */) {
      let {
        data: {
          payload: {
            blobContainerURI,
            // container,
            sas: sasQueryString,
          },
        },
      } = await AuthRequest.get("/Storage/sas");
      blobContainerURI = trimTrailingSlash(blobContainerURI);
      const fileName = createUniqueNameForFile(file);
      const fileURL = `${blobContainerURI}/${fileTypeInfo.path}/${fileName}`;
      const uploadURL = `${fileURL}${sasQueryString}`;
      const uploader = new FileUploader(file, uploadURL);
      await uploader.start();
      return fileURL;
    };
  },
};
