import React from "react";
import CreditsGetStarted from "./myCredits/CreditsGetStarted";
import MyCreditsOverview from "./myCredits/MyCreditsOverview";
import Rewards from "./myCredits/rewards/Rewards";
import Loader from "../../../components/Loader";
import ErrorDisplay from "../../../components/ErrorDisplay";
import { handleErrorMessage } from "../../../lib";

export default class MyCredits extends React.PureComponent {
  componentDidMount() {
    this.getStudentDetails();
  }

  getStudentDetails = (isRefresh) => {
    const {
      myJewishU: { data: { eduStudentEnrollmentID } = {} },
    } = this.props;
    if (eduStudentEnrollmentID) {
      this.props.actions.getStudent(isRefresh);
    }
  };

  render() {
    const {
      actions: { getRewards, getStudentRewards, doRewardRequest },
      myJewishU: { data: { eduStudentEnrollmentID, eduChabadHouse } = {} },
      rewardRequest,
      rewards,
      student: { data: student, error, loading },
      studentRewards,
      sys: { eduRewardTypes = [] },
    } = this.props;

    return loading ? (
      <Loader className="full-page-loader" />
    ) : (
      <div className="my-credits-page">
        {error || !student ? (
          <ErrorDisplay errorMessage={handleErrorMessage(error)} />
        ) : (
          <React.Fragment>
            <MyCreditsOverview {...student} />
            {!eduStudentEnrollmentID ? (
              <CreditsGetStarted />
            ) : (
              <React.Fragment>
                <div className="credits-section container mb-80">
                  <p className="credits-section-title xxl-text mb-24 fw-700">
                    Overview
                  </p>
                  <div className="credits-overview-cards">
                    <div className="card text-center">
                      <p className="large-text fw-600 mb-24 accent-text-secondary uppercase-text text-center">
                        Earn Credit
                      </p>
                      <p className="fw-500 mb-8">4 Credits per Course</p>
                    </div>
                    <div className="mobile-hidden">
                      <i className="material-icons accent-text-secondary">
                        arrow_forward_ios
                      </i>
                      <i
                        className="material-icons accent-text-secondary"
                        style={{ marginLeft: "-12px" }}
                      >
                        arrow_forward_ios
                      </i>
                    </div>
                    <div className="desktop-hidden tablet-hidden text-center">
                      <i className="material-icons accent-text-secondary">
                        expand_more
                      </i>
                    </div>
                    <div className="card">
                      <p className="large-text fw-600 mb-24 accent-text-secondary uppercase-text text-center">
                        Redeem Credit
                      </p>
                      <p className="fw-500 text-center line-height-double">
                        Save up for a grand trip, or redeem on demand. See below
                        for details.
                      </p>
                    </div>
                  </div>
                </div>
                <Rewards
                  getRewards={getRewards}
                  getStudentRewards={getStudentRewards}
                  refreshStudentDetails={() => this.getStudentDetails(true)}
                  requestReward={doRewardRequest}
                  rewardRequest={rewardRequest}
                  rewards={rewards}
                  rewardTypes={eduRewardTypes}
                  student={student}
                  studentRewards={studentRewards}
                  currencyCode={eduChabadHouse?.currencyCode}
                  isGrandTripPage={false}
                  isLoggedIn={true}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
