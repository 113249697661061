import React, { memo } from "react";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";

interface FAQ {
  question: string;
  answer: string;
}

const faqsData: FAQ[] = [
  {
    question: "Who can go on the trip?",
    answer:
      "Eligible JewishU students, currently enrolled in college, ages 18-24, who’ve earned the required credits within the stipulated timeframe, and are deemed qualified for group travel to said location. Trips are a 4x bonus of JewishU credits, and JewishU reserves the right to withhold this bonus if it deems necessary.",
  },
  {
    question: "Am I guaranteed a spot on the trip?",
    answer:
      "We are committed to getting as many eligible students on the trip as possible, but in life there are no guarantees. If you qualify and there is no room, you will be given priority enrollment in the next trip. Or you can opt to cash out at the standard JewishU credits value.",
  },
  {
    question: "How much does the trip cost?",
    answer:
      "From departure city through return, these expensive trips are entirely subsidized by Chabad on Campus and their generous donors, who believe in you and the value of a Jewish education. You are responsible for travel to the departure city, a security deposit (amount varies based on trip type), and $100 for local tour staff gratuities.",
  },
  {
    question: "Do JewishU credits roll over from year to year?",
    answer:
      "As long as you're learning, your credits maintain their standard value. In order for credits to increase to a 4x factor and qualify for a trip, they must be earned within the specified timeframe per trip. All credits expire 12 months after your last JewishU course.",
  },
  {
    question: "Can I defer my trip?",
    answer:
      "To take advantage of the 4x bonus on your credit value, you must go on a trip immediately following the semester/quarter you qualified. If there is no room for you on a trip, you will be given priority on the next trip. If you don't, your credits retain their standard value as long as they are active, which is until twelve months after you’ve taken your last JewishU course.",
  },
  {
    question: "Will there be free time on the trip to explore on my own?",
    answer:
      "The trip itinerary and program is very full and students must participate in all of it. Students will be granted some free time included within the framework of a tight program schedule. Due to security concerns, students must remain with the group at all times.",
  },
  {
    question: "What security precautions does Chabad on Campus take?",
    answer:
      "Your safety is our number one priority. We do not travel to or through high-risk areas, and when called for by common local practice, we are accompanied by security personnel.",
  },
  {
    question: "What travel documents do I need for international travel?",
    answer:
      "You need a valid passport that does not expire for at least six months from your return date. US citizens do not require visas to any of our international destinations. If you are of a different nationality, please check with the appropriate authorities to ensure you can enter and leave the country on your specific JewishU International trip.",
  },
  {
    question: "Do I need traveler's insurance?",
    answer:
      "Yes. All participants must have traveler's insurance, which you are responsible for. (We recommend a vendor in our trip details email sent to confirmed participants).",
  },
  {
    question:
      "For international travel, what type of cell phone service is available?",
    answer:
      "Every carrier and destination differs, so it is best to check with your carrier and to search the respective app stores for E-sims. (We generally recommend something in our trip details email sent to confirmed participants).",
  },
  {
    question: "What time do I need to be at the airport?",
    answer:
      "Upon confirmation of acceptance on the trip you will receive an email with information about flights, arrival and departure times.",
  },
  {
    question: "Can I extend my stay?",
    answer:
      "Yes. Cost and availability will vary depending on your international trip destination. All costs associated with your extension are your responsibility alone.",
  },
];

export const GrandTripFAQs = () => {
  return (
    <div className="faqs">
      <div className="container">
        <h4 className="faq-title accent-text-secondary fw-700 mb-40">FAQs</h4>
        {faqsData.map((faq, index) => (
          <div className="faq" key={index}>
            <p className="large-text fw-600 accent-text-secondary line-height-double mb-8">
              {faq.question}
            </p>
            <p className="large-text line-height-double">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(withLayout()(withAppInsights(GrandTripFAQs)));
